<template>
  <div>
    <div class="title flexB" style="display: flex;">
      <h1>{{ $t("top-select-live-data-details") }}</h1>
    </div>
    <div class="contents channelDetail">
      <div class="box one liveBox" v-if="liveObj && liveObj.assignedStatus == 'Assigned'">
        <videoPlayer :src="liveObj.liveURL ? liveObj.liveURL : ''" :isVod="false"/>
        <div class="buttonWrap flexB">
          <div>
<!--            <button class="large">{{ $t("live-data-channel-end") }}</button>-->
            <button class="large margin8" @click="reset">
              {{ $t("btn-refresh") }}
            </button>
          </div>
          <!-- <button class="pointLine large">채널테스트</button> -->
        </div>
      </div>
      <div class="box one detail">
        <div class="flex">
          <p class="bold">{{ $t("live-data-channel-id") }}</p>
          <p>{{ liveObj.channelId }}</p>
        </div>
        <div class="flex">
          <p class="bold">{{ $t("live-data-channel-status") }}</p>
          <p>
            {{$t(getChannelStatusName(liveObj))}}
          </p>
        </div>
        <div class="flex">
          <p class="bold">URL</p>
          <p>{{ liveObj.liveURL }}</p>
        </div>
        <div class="flex">
          <p class="bold">KEY</p>
          <p>{{ liveObj.streamKey }}</p>
        </div>
        <div class="flex">
          <p class="bold">{{ $t("live-data-channel-choose-server") }}</p>
          <p>{{ liveObj.streamServer == null ? "-" : liveObj.streamServer.name }}</p>
        </div>
        <div class="flex">
          <p class="bold">{{ $t("live-data-channel-quality") }}</p>
          <p>720p</p>
        </div>
        <div class="flex">
          <p class="bold">{{ $t("live-data-channel-reservation") }}</p>
          <p>
            {{ liveObj.assignedDate == null || liveObj.channelStatus == "Wait" ? "-" : moment(liveObj.assignedDate).format("YYYY.MM.DD HH:mm:ss") }}
          </p>
        </div>
        <div class="flex">
          <p class="bold">{{ $t("live-data-channel-broadcasting") }}</p>
          <p>{{ liveObj.eventId == null || liveObj.channelStatus == "Wait" ? "-" : moment(liveObj.recordDate).format("YYYY.MM.DD HH:mm:ss") }}</p>
        </div>
        <div class="flex">
          <p class="bold">{{ $t("event-data-site-name") }}</p>
          <p>{{ liveObj.eventTitle == null || liveObj.channelStatus == "Wait" ? "-" : liveObj.eventTitle }}</p>
        </div>
        <div class="flex">
          <p class="bold">{{ $t("event-data-site-num") }}</p>
          <p>{{ liveObj.eventId == null || liveObj.channelStatus == "Wait" ? "-" : liveObj.eventViewId != null ? liveObj.eventViewId  : liveObj.eventId }}</p>
        </div>
        <div class="flex">
          <p class="bold">{{ $t("data-operation-device-type") }}</p>
          <p>
            {{liveObj.eventId == null || liveObj.channelStatus == "Wait" ? "-" :  $t(getDeviceTypeName(liveObj.assignedDeviceType)) }}
          </p>
        </div>
        <div class="flex">
          <p class="bold">{{ $t("live-data-channel-operator") }}</p>
          <p>
            {{ liveObj.assignedUserName ? liveObj.assignedUserName : "-" }}
            <span v-show="liveObj.deviceOwnerPhone"
              >({{ liveObj.deviceOwnerPhone }})</span
            >
          </p>
        </div>
        <router-link :to="`/${accountId}/channel/channelList`" style="width: 100%;display: flex;justify-content: center;">
          <button class="medium right" style="padding: 0 40px;">{{ $t("btn-list") }}</button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import videoPlayer from "@/components/video/videoPlayer";
import { fetchChannel ,getChannelStatusName,getDeviceTypeName } from "@/api/channel";
export default {
  name: "ChannelDetail",
  components: {
    videoPlayer,
  },
  data() {
    return {
      moment: moment,
      liveObj: {},
      channelId: "",
      accountId: localStorage.getItem("accountId"),
      currentContentsId: null,
      contents: null,
      name: "",
      channelUrl: "",
      channelKey: "",
      liveUrl: "",
      status: "",
      registerUserId: "",
      modifyUserId: "",
      modifyDate: "",
      registerDate: "",
      eventId: null,
      event: null,
      deviceType: null,
      deviceId: null,
      deviceOwnerName: null,
      deviceOwnerPhone: null,
    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 8, sub: 0 });
  },
  mounted() {
    this.getChannelInfo();
  },
  methods: {
    reset() {
      window.document.location.href = window.document.location;
    },
    getChannelStatusName(data){
      return getChannelStatusName(data);
    },
    getDeviceTypeName(data){
      return getDeviceTypeName(data);
    },
    getChannelInfo() {
      let channelId = this.$route.query.channelId;
      fetchChannel(channelId).then((res) => {
        if (res.data.result == 0) {
          this.liveObj = res.data.data;

        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
  },
};
</script>